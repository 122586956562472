import React, { useState } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { Button, Link, FormControl, Grid } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { hasJobEditAddressPerm } from 'constants/permissions'
import {
  JOB_HABITATION_TYPES,
  ORDER_UPDATE_NOT_VALID_STATUS,
  ORDER_UPDATE_PHONE_NUMBER_ONLY_STATUSES,
  PK_TYPE,
} from 'constants/Jobs'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import FieldPlace from 'components/shared/FieldPlace/FieldPlace'
import Label from 'components/shared/Label/Label'
import RenderRadio from 'components/shared/Form/RenderRadio'
import RenderSwitch from 'components/shared/Form/RenderSwitch'
import './JobDetailsClientForm.css'

const JobDetailsClientForm = ({
  translate,
  classes,
  handleSubmit,
  submitFormDispatch,
  changeFieldValue,
  token,
  currentJob,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)

  const handleResponse = ({ currentTarget: { value } }) => {
    if (value === 'true') {
      submitFormDispatch()
    }

    setDialogOpened(!confirmDialogOpened)
  }

  const onAutoCompleteLocation = place => {
    const requiredComponents = place.address_components.filter(
      element =>
        element.types.includes('street_number') ||
        element.types.includes('route') ||
        element.types.includes('postal_code') ||
        element.types.includes('locality'),
    )

    if (place.place_id && requiredComponents.length === 4) {
      let streetNumber = null
      let route = null
      requiredComponents.forEach(element => {
        if (element.types.includes('street_number')) {
          streetNumber = element.long_name
        }

        if (element.types.includes('route')) {
          route = element.long_name
        }

        if (element.types.includes('postal_code')) {
          changeFieldValue('postCode', element.long_name)
        }

        if (element.types.includes('locality')) {
          changeFieldValue('city', element.long_name)
        }
      })

      if (streetNumber && route) {
        changeFieldValue('street', `${streetNumber} ${route}`)
      }
    }
  }

  const onChangePlace = () => {
    changeFieldValue('address', null)
    changeFieldValue('street', null)
    changeFieldValue('postCode', null)
    changeFieldValue('city', null)
  }

  const statusIsNotValidToUpdateJob = ORDER_UPDATE_NOT_VALID_STATUS.includes(
    currentJob.status,
  )

  const statusAuthorizePhoneNumberOnly = ORDER_UPDATE_PHONE_NUMBER_ONLY_STATUSES.includes(
    currentJob.status,
  )

  const userHasPermission = hasJobEditAddressPerm(token)
  const buttonIsDisabled =
    !userHasPermission ||
    statusIsNotValidToUpdateJob ||
    currentJob.type !== PK_TYPE
  const inputDisabled =
    !userHasPermission ||
    statusIsNotValidToUpdateJob ||
    statusAuthorizePhoneNumberOnly

  return (
    <>
      <Button
        name="generateDisputeDocuments"
        className={classes.buttonSecondary}
        variant="contained"
        component={Link}
        onClick={() => setDialogOpened(true)}
        disabled={buttonIsDisabled}
      >
        <EditOutlined />
        {translate('job.details.client.form.pop_in.button')}
      </Button>
      {confirmDialogOpened && (
        <ConfirmDialog
          title={translate('job.details.client.form.title')}
          onClose={handleResponse}
        >
          <FormControl className={classes.formControl}>
            <form onSubmit={handleSubmit}>
              <Grid className={classes.marginBottom}>
                <Label
                  label={translate(
                    'job.details.client.form.field.phone_number.label',
                  )}
                />
                <Field
                  id="phone_number"
                  name="phoneNumber"
                  component="input"
                  fullWidth
                  placeholder={translate(
                    'job.details.client.form.field.phone_number.placeholder',
                  )}
                  value=""
                  types={['tel']}
                  className={classes.simpleInputText}
                />
              </Grid>
              <Grid>
                <Label
                  label={translate(
                    'job.details.client.form.field.address.label',
                  )}
                />
                <FieldPlace
                  fullWidth
                  icon={<EditOutlined />}
                  label={translate(
                    'job.details.client.form.field.address.placeholder',
                  )}
                  placeholder={translate(
                    'job.details.client.form.field.address.placeholder',
                  )}
                  types={['address']}
                  name="address"
                  onAutoCompleteLocation={place =>
                    onAutoCompleteLocation(place)
                  }
                  onChange={onChangePlace}
                  className={classes.inputText}
                  disabled={inputDisabled}
                />
              </Grid>
              <Grid className={classes.margin}>
                <Label
                  label={translate(
                    'job.details.client.form.field.habitation_type.label',
                  )}
                />
              </Grid>
              <Grid>
                <Field
                  name="type"
                  component={RenderRadio}
                  className={classes.checkboxInterventionZone}
                  choices={JOB_HABITATION_TYPES.map(option => ({
                    name: translate(
                      `job.details.client.form.field.habitation_type.${option}`,
                    ),
                    value: option,
                    key: option,
                  }))}
                  disabled={inputDisabled}
                />
              </Grid>
              <Grid className={classes.margin}>
                <Label
                  label={translate(
                    'job.details.client.form.field.accessibility.label',
                  )}
                />
              </Grid>
              <Grid>
                <Field
                  name="elevator"
                  className={classes.input}
                  component={RenderSwitch}
                  label={translate(
                    'job.details.client.form.field.accessibility.elevator',
                  )}
                  disabled={inputDisabled}
                />
                <Field
                  name="parking"
                  className={classes.input}
                  component={RenderSwitch}
                  label={translate(
                    'job.details.client.form.field.accessibility.parking',
                  )}
                  disabled={inputDisabled}
                />
              </Grid>
              <Grid>
                <Label
                  label={translate(
                    'job.details.client.form.field.additional_information',
                  )}
                />
                <Field
                  id="additional_information"
                  name="additionalInformation"
                  component="textarea"
                  label={translate(
                    'job.details.client.form.field.additional_information',
                  )}
                  fullWidth
                  placeholder={translate('resources.jobs.comment.placeholder')}
                  value=""
                  className={classes.inputText}
                  disabled={inputDisabled}
                />
              </Grid>
            </form>
          </FormControl>
        </ConfirmDialog>
      )}
    </>
  )
}

JobDetailsClientForm.propTypes = {
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitFormDispatch: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    buttonSecondary: PropTypes.string.isRequired,
    inputText: PropTypes.string.isRequired,
    simpleInputText: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    label: PropTypes.string,
    formControl: PropTypes.string.isRequired,
    checkboxInterventionZone: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
    marginBottom: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.shape({}).isRequired,
  currentJob: PropTypes.shape({
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    substatus: PropTypes.string,
  }),
}

JobDetailsClientForm.defaultProps = {
  currentJob: {
    substatus: null,
  },
}

export default JobDetailsClientForm
