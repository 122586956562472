import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { PackageActions } from 'store/products/packageActions'
import { PackageSelector } from 'store/products/packageSelector'
import ProSearch from './ProsSearch'

const ProsSearchContainer = ({
  getPackageList,
  packageList,
  isManualMatchingNewProSearchFfEnabled,
  handleSearchChange,
  handleReset,
  initialValues,
  tabValue,
}) => {
  useEffect(() => {
    getPackageList()
    handleReset()
  }, [getPackageList])

  return (
    <ProSearch
      packageList={packageList}
      isManualMatchingNewProSearchFfEnabled={
        isManualMatchingNewProSearchFfEnabled
      }
      handleSearchChange={handleSearchChange}
      handleReset={handleReset}
      initialValues={initialValues}
      tabValue={tabValue}
    />
  )
}

ProsSearchContainer.propTypes = {
  packageList: PropTypes.shape({}).isRequired,
  getPackageList: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func,
  handleReset: PropTypes.func.isRequired,
  isManualMatchingNewProSearchFfEnabled: PropTypes.bool,
  initialValues: PropTypes.shape({
    tenYearInsuranceValid: PropTypes.bool,
    liabilityInsuranceValid: PropTypes.bool,
  }),
  tabValue: PropTypes.number,
}

ProsSearchContainer.defaultProps = {
  handleSearchChange: undefined,
  isManualMatchingNewProSearchFfEnabled: false,
  initialValues: {
    tenYearInsuranceValid: true,
    liabilityInsuranceValid: true,
  },
  tabValue: 0,
}

const mapStateToProps = state => ({
  packageList: PackageSelector.typeListIndexedByCodeSelector()(state),
  initialValues: {
    tenYearInsuranceValid: true,
    liabilityInsuranceValid: true,
  },
})

const mapDispatchToProps = dispatch => ({
  getPackageList: () => dispatch(PackageActions.getTypeList().request()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProsSearchContainer)
