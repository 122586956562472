import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { existOrBoolJobValue } from 'helpers/utils/job/job'
import i18n from 'providers/i18n/I18nProvider'

import { Typography } from '@material-ui/core'
import InlineTextField from 'components/shared/InlineTextField/InlineTextField'
import FieldEmail from 'components/shared/FieldEmail/FieldEmail'
import InlineTextHtml from 'components/shared/InlineTextHtml/InlineTextHtml'
import JobDetailsClientFormContainer from 'components/Jobs/JobDetails/JobDetailsClientForm/JobDetailsClientFormContainer'
import { hasJobEditAddressPerm } from 'constants/permissions'
import { PK_TYPE } from 'constants/Jobs'

const JobDetailsClient = ({
  translate,
  gender,
  lastName,
  firstName,
  email,
  phoneNumber,
  city,
  postcode,
  street,
  type,
  elevator,
  parking,
  additionalInformation,
  token,
  jobType,
}) => {
  const noInfoText = translate('resources.customers.values.noInfo')

  return (
    <>
      <InlineTextField
        label={translate('resources.jobs.fields.gender')}
        field={translate(`resources.customers.values.${gender}`)}
        isBold
      />
      <InlineTextField
        label={translate('resources.jobs.fields.ho_lastName')}
        field={lastName}
        isBold
      />
      <InlineTextField
        label={translate('resources.jobs.fields.ho_firstName')}
        field={firstName}
        isBold
      />
      <InlineTextField
        label={translate('resources.jobs.fields.address')}
        field={street ? `${street} ${postcode} ${city}` : noInfoText}
        isBold
      />
      <InlineTextField
        label={translate('resources.customers.fields.type_habitat')}
        field={
          type ? translate(`resources.customers.values.${type}`) : noInfoText
        }
        isBold
      />
      <InlineTextField
        label={translate('resources.customers.fields.elevator')}
        field={existOrBoolJobValue(elevator)}
        isBold
      />
      <InlineTextField
        label={translate('resources.customers.fields.parking')}
        field={existOrBoolJobValue(parking)}
        isBold
      />
      <InlineTextField
        label={translate('resources.customers.fields.additionalInformation')}
        field={additionalInformation || noInfoText}
        isBold
        isPreWrap
        Element="p"
      />
      <InlineTextField
        label={translate('resources.customers.fields.phoneNumber')}
        field={phoneNumber}
        isBold
      />
      <FieldEmail
        label={translate('resources.customers.fields.email')}
        Component={InlineTextHtml}
        email={email}
        isBold
      />
      <JobDetailsClientFormContainer
        street={street}
        postCode={postcode}
        city={city}
        type={type}
        elevator={elevator}
        parking={parking}
        phoneNumber={phoneNumber}
        additionalInformation={additionalInformation}
        token={token}
      />
      {!hasJobEditAddressPerm(token) && (
        <>
          <br />
          <Typography variant="caption">
            {translate('resources.customers.fields.modificationPerm')}
          </Typography>
        </>
      )}
      {jobType !== PK_TYPE && (
        <>
          <br />
          <Typography variant="caption">
            {translate('resources.customers.fields.modification.notInitial')}
          </Typography>
        </>
      )}
    </>
  )
}

JobDetailsClient.propTypes = {
  translate: PropTypes.func.isRequired,
  gender: PropTypes.string,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
  street: PropTypes.string,
  type: PropTypes.string,
  elevator: PropTypes.bool,
  parking: PropTypes.bool,
  additionalInformation: PropTypes.string,
  token: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  }),
  jobType: PropTypes.string.isRequired,
}

JobDetailsClient.defaultProps = {
  gender: undefined,
  lastName: undefined,
  firstName: undefined,
  email: undefined,
  phoneNumber: undefined,
  city: undefined,
  postcode: undefined,
  street: undefined,
  type: undefined,
  elevator: undefined,
  parking: undefined,
  additionalInformation: undefined,
  token: {},
}

export default compose(i18n, memo)(JobDetailsClient)
